<template>
  <div
    v-if="isInitiated && isLoggedIn && profile.Id > 0"
    class="dashboard-content-wrapper"
  >
    <div class="columns is-multiline">
      <div class="column is-one-third is-hidden-mobile">
        <div class="columns is-multiline">
          <div
            v-if="!isOutdatedBrowser"
            class="column is-full"
          >
            <booking-widget class="box p-4 has-background-grey-lighter" />
          </div>

          <!-- MAP -->
          <div
            v-if="clientWidth > 1023"
            class="column-map column is-full"
          >
            <div class="box p-4 has-background-grey-lighter">
              <h3 class="title is-3 has-text-blue-dark has-text-centered">People and locations</h3>
              <visualisation-map
                v-if="clientWidth > 1023"
                :isTile="true"
                mapHeight="360px"
                class="has-margin-bottom"
              />
              <div
                class="wrapper-buttons"
                :class="{'has-text-centered': clientWidth < 1024}"
              >
                <router-link
                  :to="{ name: 'Network' }"
                  @click.native="$googleAnalytics.pushEvent(`Page-${routeName}`, 'button-FindPeople', 'PeopleAndLocations')"
                  class="button is-primary"
                  :class="clientWidth < 1024 ? 'is-normal has-margin-bottom':'is-small has-margin-bottom'"
                >Find people and expertise</router-link>
                <router-link
                  v-if="clientWidth < 1024"
                  @click.native="$googleAnalytics.pushEvent(`Page-${routeName}`, 'button-EnlargeMap', 'PeopleAndLocations')"
                  :to="{ name: 'Locations' }"
                  class="button is-primary"
                  :class="clientWidth < 1024 ? 'is-normal':'is-small has-margin-bottom'"
                >Explore locations</router-link>
                <router-link
                  v-if="clientWidth > 1023"
                  @click.native="$googleAnalytics.pushEvent(`Page-${routeName}`, 'button-EnlargeMap', 'PeopleAndLocations')"
                  :to="{ name: 'Network', query: { v: 'map' } }"
                  class="button is-primary is-hidden-touch"
                  :class="clientWidth < 1024 ? 'is-normal':'is-small has-margin-bottom'"
                >Enlarge map</router-link>
              </div>
            </div>
          </div>

          <!-- REVIEWS -->
          <transition
            name="fade"
            mode="out-in"
          >
            <div
              v-show="hasOpenReviews"
              class="column is-full"
            >
              <open-reviews
                @hasReviews="hasReviews"
                class="box p-4 has-background-grey-lighter wrapper"
              ></open-reviews>
            </div>
          </transition>
        </div>
      </div>

      <div class="column is-two-thirds">
        <div class="columns is-multiline">
          <div
            ref="columnWorkingOn"
            class="column"
          >
            <div class="box matches-wrapper p-4 has-background-grey-lighter">
              <transition
                name="fade"
                mode="out-in"
              >
                <div
                  v-if="profile.FirstName !== ''"
                  class="greeting-wrapper"
                >
                  <div
                    id="headerGreeting"
                    class="is-size-3-mobile is-size-3-tablet is-size-3-desktop has-text-blue-dark has-text-weight-bold has-text-centered"
                    v-cloak
                  >{{ getGreetingText() }} {{ profile.FirstName }},</div>
                  <div class="has-text-centered has-margin-bottom">
                    Tell us your focus and our algorithm will match you with
                    experts, locations, events and other relevant content.
                  </div>
                  <checkin-workingon
                    name="checkinWorkinOn"
                    class="has-margin-bottom"
                    placeholder="My focus today is..."
                  />
                  <div class="has-text-centered">
                    <router-link
                      @click.native="$googleAnalytics.pushEvent(`Page-${routeName}`, 'link-AboutPassport', 'Focus section')"
                      :to="{ name: 'AboutPassport' }"
                    >
                      I want to learn more about the S2M passport and its algorithm.
                    </router-link>
                  </div>
                </div>
              </transition>
            </div>
          </div>

          <!-- BOOKINGS -->
          <div
            ref="columnUpcomingEvents"
            class="column is-half-tablet is-one-third-desktop column-upcomingEvents"
            :class="{'is-hidden-touch': hasUpcomingEvents }"
          >
            <Upcoming
              class="box-upcoming box p-4 has-background-grey-lighter"
              @hasUpcomingEvents="val => hasUpcomingEvents = val"
            />
          </div>

          <!-- MATCHES -->
          <div
            v-if="passportState !== null"
            key="matchContainer"
            class="column is-full"
          >
            <transition
              name="fade"
              mode="out-in"
            >
              <matches
                v-if="passportState !== null"
                key="matchContainerB"
                name="matches"
                class="box p-4 has-background-info-lighter"
                :showLocation="true"
                :canEdit="true"
                :showTags="true"
                @loadingReady="loadingMatchesCheck"
              ></matches>
            </transition>
          </div>

          <div
            v-if="currentCheckin"
            class="column is-full"
          >
            <div class="box p-4 has-background-grey-lighter">
              <CurrentCheckin />
            </div>
          </div>

          <!-- ASK A QUESTION -->
          <div class="column is-full">
            <create-question
              v-if="isLoggedIn"
              :viewType="'widget'"
              :setCurrentStep="1"
              class="box questionWidgetContainer p-4 has-background-primary"
            ></create-question>
          </div>

          <div
            v-if="clientWidth < 1024"
            class="column is-full"
          >
            <div class="box p-4 has-background-grey-lighter">
              <h3 class="title is-3 has-text-blue-dark has-text-centered">People and locations</h3>
              <visualisation-map
                :isTile="true"
                mapHeight="300px"
                class="has-margin-bottom"
              />
              <router-link
                :to="{ name: 'Network' }"
                @click.native="$googleAnalytics.pushEvent(`Page-${routeName}`, 'button-FindPeople', 'PeopleAndLocations')"
                class="button is-primary has-margin-right"
                :class="{ 'is-small' : clientWidth < 460 }"
              >Find people and expertise</router-link>
              <router-link
                :to="{ name: 'Network', query: { v: 'map' } }"
                @click.native="$googleAnalytics.pushEvent(`Page-${routeName}`, 'button-EnlargeMap', 'PeopleAndLocations')"
                class="button is-primary"
                :class="{ 'is-small' : clientWidth < 460 }"
              >Enlarge map</router-link>
            </div>
          </div>

          <!-- PROFILE STATS -->
          <div class="column is-full">
            <profile-stats
              v-if="isLoggedIn && isInitiated && profile.Id > 0"
              :viewType="'compact'"
              class="box p-4 has-background-grey-lighter wrapper"
            ></profile-stats>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import VisualisationMap from '@/components/visualisationMap/VisualisationMap'
import CurrentCheckin from '@/components/dashboard/CurrentCheckin'
import Upcoming from '../components/dashboard/Upcoming.vue'
import BookingWidget from '../components/BookingWidget.vue'

export default {
  name: 'dashboard',

  components: {
    'visualisation-map': VisualisationMap,
    CurrentCheckin,
    Upcoming,
    BookingWidget,
  },

  data() {
    return {
      loadingMatchesReady: false,
      countryId: 152,
      hasOpenReviews: false,
      siteMenuOpPageVisible: false,
      s2mWidgetClass: 'has-background-white',
      s2mSearchWidgetInputBorderColor: '',
      s2mWSearchWidgetData: {},
      usps: [],
      clientWidth: window.innerWidth,
      jitsiApi: null,
      routeName: this.$route.name,
      hasUpcomingEvents: false,
    }
  },

  computed: {
    ...mapState(['isOutdatedBrowser']),
    ...mapGetters({
      activeDrawerType: 'getActiveDrawerType',
      connectionStatus: 'getConnectionStatus',
      currentCheckin: 'getCurrentCheckin',
      currentHour: 'getCurrentHour',
      isFirstRun: 'getIsFirstRun',
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'getIsLoggedIn',
      notifications: 'getNotifications',
      passportState: 'getPassportState',
      profile: 'getProfile',
      showTagValidator: 'getShowTagValidator'
    }),

    ready() {
      return this.isLoggedIn && this.isInitiated
    },
  },

  watch: {
    currentCheckin(newVal, oldVal) {
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry()
      }
    },

    activeDrawerType() {
      if (this.activeDrawerType === '') {
        this.siteMenuOpPageVisible = false
      }
      if (typeof this.$redrawVueMasonry === 'function') {
        this.$redrawVueMasonry()
      }
    },
  },

  created() {
    window.scrollTo(0, 0)

    this.$store.dispatch('getPassportState')
  },

  mounted() {
    window.addEventListener('resize', this.resizeHandler)
    this.resizeHandler()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
    this.$store.commit('setSelectedPublicProfile', {
      profileId: 0,
      view: 'detail'
    })
    this.$store.commit('setActiveDrawerType', '')
  },

  methods: {
    resizeHandler() {
      this.clientWidth = window.innerWidth
      if (self.activeDrawerType === 'mobileAskQuestionWidget') {
        self.$store.commit('setActiveDrawerType', '')
      }
      // if (this.clientWidth < 769) {
      //   let elm = this.$refs.columnUpcomingEvents
      //   elm.parentNode.insertBefore(elm, elm.parentNode.firstChild)
      // } else {
      //   let elm = this.$refs.columnWorkingOn
      //   elm.parentNode.insertBefore(elm, elm.parentNode.firstChild)
      // }
    },

    buildSlideMarkup(count) {
      let slideMarkup = ''
      for (var i = 1; i <= count; i++) {
        slideMarkup += '<slide><span class="label">' + i + '</span></slide>'
      }
      return slideMarkup
    },

    getGreetingText() {
      let d = new Date()
      if (d.getHours() < 12) {
        return 'Good morning'
      } else if (d.getHours() >= 12 && d.getHours() < 18) {
        return 'Good afternoon'
      } else {
        return 'Good evening'
      }
    },

    hasReviews(val) {
      this.hasOpenReviews = val
    },

    loadingMatchesCheck(val) {
      let self = this
      this.loadingMatchesReady = val
      let t = setTimeout(() => {
        if (typeof self.$redrawVueMasonry === 'function') {
          self.$redrawVueMasonry()
        }
        clearTimeout(t)
      }, 2000)
    },

    showHideSiteMenuOpPage() {
      let val = this.activeDrawerType !== '' ? '' : 'siteMenuOpPage'
      this.$store.commit('setActiveDrawerType', val)
    },

    showAskQuestionWidget() {
      this.$store.commit('setActiveDrawerType', 'askQuestionDrawer')
    }
  }
}
</script>

<style lang="scss" scoped>
.box-s2mSearchWidget {
  .s2mSearchWidget {
    &.is-mobile {
      .s2m-upsell-points-wrapper {
        text-align: left !important;
      }
    }
  }

  &.has-background-blue-dark {
    color: white;
    .ups svg.has-text-blue-dark {
      fill: white;
    }
    .s2mSearchWidget {
      a.button {
        color: white;
        border-color: white;
        background-color: $blue-dark;

        &:active {
          color: white;
          border-color: white;
          background-color: $blue-dark;
        }

        &:hover {
          color: $blue-dark;
          border-color: white;
          background-color: white;
        }
      }
    }
  }

  &.has-background-green {
    color: white;
    .ups {
      font-weight: $weight-bold;
      svg.has-text-blue-dark {
        fill: white;
      }
    }
    .s2mSearchWidget {
      a.button {
        color: white;
        border-color: white;
        background-color: $green;

        &:hover {
          color: $green;
          border-color: white;
          background-color: white;
        }
      }
    }
  }
}

/**
* Widget button
*/
.c_lab-bw-widget {
  .c_lab-bw-button {
    text-transform: uppercase !important;
    font-weight: $weight-bold !important;
    &.is-success {
      @include buttonStyle($green);
    }

    &.is-info {
      @include buttonStyle($blue);
    }

    &.is-blue-dark {
      @include buttonStyle($blue-dark);
    }

    &.is-cyan {
      @include buttonStyle($cyan);
    }

    &.is-grey-light {
      @include buttonStyle($grey-light);
    }

    &.is-grey {
      @include buttonStyle($grey);
    }

    &.is-primary {
      @include buttonStyle($cyan);
    }

    &.is-red-light {
      @include buttonStyle($red-light);
    }

    &.is-delete {
      @include buttonStyle($red);
    }
  }
}

.wrapper-buttons {
  .button {
    &:not(:last-child) {
      margin-right: 10px;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-right: 0;
    }
  }
}

.box-upcoming-reservations {
  height: 100%;
}

.box-upcoming {
  height: 100%;
}

.virtual-private-room {
  .row {
    display: flex;
    align-items: center;
    .font-icon {
      $iconSize: 98px;
      display: inline-block;
      height: $iconSize;
      width: $iconSize;
      font-size: $iconSize;
      margin-right: 15px;
      line-height: 1;
      &:before {
        line-height: 1;
      }
    }
  }
}

.column-upcomingEvents {
  @media screen and (max-width: 768px) {
    height: 260px;

    ::v-deep .title-nrPresentation {
      p-4-top: 0 !important;
      p-4-bottom: 0 !important;
    }

    ::v-deep .title-item {
      p-4-bottom: 10px !important;
    }
  }

  @media screen and (max-width: 533px) {
    order: -1;
  }
}

#mobile-footerbar {
  left: 0;
  bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 12;
  border-top: 2px solid $grey-light;

  .level {
    .level-item {
      margin: 0;
      p-4-top: 5px;
      p-4-bottom: 5px;
      @media screen and (max-width: 460px) {
        p-4-top: 10px;
        p-4-bottom: 10px;
        .button {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 400px) {
        .button {
          font-size: $size-7;
        }
      }
    }
  }
}
</style>
