<template>
  <div>
    <div
      class="is-size-6-mobile is-size-4-tablet is-size-3-desktop has-text-blue-dark has-text-weight-bold has-text-centered"
      :class="{'has-margin-bottom': checkinType !== 'location'}"
      v-html="title"
    ></div>
    <div
      v-if="checkinType === 'location'"
      class="has-text-centered has-margin-bottom"
    >Click the button to let the people at your location know you're present!</div>
    <div class="columns is-multiline is-mobile">
      <!-- My checkin -->
      <div class="column is-half-mobile is-half-tablet is-one-third-desktop">
        <CheckinItem
          :checkin="currentCheckin"
          :showLocation="showLocation"
        />
      </div>
      <!-- Other checkins -->
      <div
        v-for="(checkin, index) in checkins"
        :key="'c' + index"
        class="column is-half-mobile is-half-tablet is-one-third-desktop"
      >
        <CheckinItem
          :checkin="checkin"
          :showLocation="showLocation"
          sectionName="CurrentCheckin"
        />
      </div>
      <!-- Teaser -->
      <template v-for="(tile, index) in teaserTilesOutput">
        <div
          :key="'tt' + index"
          class="is-hidden-touch column-teaserTile column is-one-third"
        >
          <div class="teaserTile has-text-centered has-padding">
            <div class="cell-text has-text-primary-dark is-size-6-touch is-size-5-desktop has-text-weight-bold">
              <span v-text="tile.text"></span>
            </div>
            <div class="cell-image">
              <i
                class="font-icon has-text-primary-dark"
                :class="tile.icon"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="goto-action-buttons">
      <template v-for="(button, index) in gotoActionButtons">
        <router-link
          :key="'b'+index"
          :to="button.route"
          v-text="button.text"
          @click.native="$googleAnalytics.pushEvent(button.ga.category, button.ga.action, button.ga.label)"
          :class="button.type === 'button' ? 'button is-primary' : ''"
        ></router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import checkinProvider from '../../providers/checkin'
import CheckinItem from '@/components/CheckinItem'

export default {
  components: {
    CheckinItem
  },
  data() {
    return {
      checkins: [],
      titleEventCheckin: 'See who is present at event: ',
      titleNetworkCheckin: 'See who is virtually present',
      titleLocationCheckin: 'See who is present at',
      teaserTilesOutput: [],
      teaserTiles: {
        location: [
          {
            icon: 's2m-icon-contact-woman',
            text: 'Bring someone with you the next time!'
          },
          {
            icon: 's2m-icon-grow-stronger',
            text: 'Connect every day with other people to collaborate and grow.'
          }
        ],

        event: [
          {
            icon: 's2m-icon-contact-woman',
            text: 'Let people know next time when you are going to an event.'
          },
          {
            icon: 's2m-icon-grow-stronger',
            text: 'Connect every day with other people to collaborate and grow.'
          }
        ]
      }
    }
  },

  computed: {
    ...mapState(['currentCheckin']),
    showLocation() {
      return (
        this.currentCheckin &&
        !this.currentCheckin.EventId &&
        !this.currentCheckin.LocationId
      )
    },
    checkinType() {
      let output = 'network'
      if (
        this.currentCheckin &&
        this.currentCheckin.EventId &&
        !this.currentCheckin.LocationId
      ) {
        output = 'event'
      } else if (this.currentCheckin && this.currentCheckin.LocationId) {
        output = 'location'
      }
      return output
    },

    title() {
      let output = this.titleNetworkCheckin
      if (this.currentCheckin && this.currentCheckin.EventId) {
        // Event title
        output =
          this.titleEventCheckin + '<br/>' + this.currentCheckin.EventName
      } else if (this.currentCheckin && this.currentCheckin.LocationId) {
        // Location title
        output =
          this.titleLocationCheckin + ' ' + this.currentCheckin.LocationName
      }
      return output
    },

    gotoActionButtons() {
      let output = []

      if (this.currentCheckin.EventId) {
        // Event checkin
        if (this.currentCheckin.LocationId) {
          output.push({
            type: 'link',
            ga: {
              category: `Page-${this.$route.name}`,
              action: 'link-FindPeople',
              label: 'CurrentCheckin | See people on event location'
            },
            text: 'See who else is at the event location',
            route: {
              name: 'NetworkLocation',
              params: {
                locationId: this.currentCheckin.LocationId
              }
            }
          })
        }
        output.push({
          type: 'button',
          ga: {
            category: `Page-${this.$route.name}`,
            action: 'button-FindPeople',
            label: 'CurrentCheckin | Checked in at event'
          },
          text: 'See who else is going',
          route: {
            name: 'EventAttendees',
            params: {
              eventId: this.currentCheckin.EventId
            }
          }
        })
      } else if (this.currentCheckin.LocationId) {
        // Location Checkin
        output.push({
          type: 'button',
          ga: {
            category: `Page-${this.$route.name}`,
            action: 'button-FindPeople',
            label: 'CurrentCheckin | Checked in at location'
          },
          text: 'See who else is here',
          route: {
            name: 'NetworkLocation',
            params: {
              locationId: this.currentCheckin.LocationId
            }
          }
        })
      } else {
        // Network checkin
        output.push({
          type: 'button',
          ga: {
            category: `Page-${this.$route.name}`,
            action: 'button-Network',
            label: 'CurrentCheckin | Checked in virtual'
          },
          text: 'Find more people and expertise',
          route: {
            name: 'Network'
          }
        })
      }

      return output
    }
  },

  created() {
    this.getCheckins()
  },

  methods: {
    getButtonLabel() {
      let output = 'See more'
      return output
    },

    /**
     * Get checkins
     */
    getCheckins() {
      let self = this

      checkinProvider.methods.getCheckins({
        date: new Date(),
        eventId: this.currentCheckin.EventId,
        locationId: !this.currentCheckin.EventId ? this.currentCheckin.LocationId : 0,
        page: 1,
        itemsPerPage: 20,
        longitude: this.currentCheckin.Longitude,
        latitude: this.currentCheckin.Latitude,
        cancelPreviousRequest: false
      })
        .then(response => {
          let _checkins = response.data.Results.filter(
            c => c.ProfileId !== self.currentCheckin.ProfileId
          )

          _checkins = self.buildCheckinArray(
            self.$objectHelper.groupDoubles(_checkins, 'ProfileId')
          )

          let _numberOfItems = _checkins.length <= 5 ? _checkins.length : 5
          _checkins = _checkins
            .sort(function() {
              return 0.5 - Math.random()
            }) // Shuffle array
            .slice(0, _numberOfItems) // Get first 2 items

          self.checkins = _checkins
          self.teaserTilesOutput = self.getTeaserTiles()
        })
    },

    /**
     * Get teaser tiles
     */
    getTeaserTiles() {
      let output = []
      if (this.checkins.length >= 2) {
        return []
      }

      if (this.currentCheckin.EventId) {
        // Event tile teasers
        output = this.teaserTiles.event
        return this.checkins.length === 0
          ? output
          : output
            .sort(function() {
              return 0.5 - Math.random()
            })
            .slice(0, 1)
      } else if (this.currentCheckin.LocationId) {
        // Location tile teasers
        output = this.teaserTiles.location
        output =
          this.checkins.length === 0
            ? output
            : output
              .sort(function() {
                return 0.5 - Math.random()
              })
              .slice(0, 1)
        return output
      }
      return []
    },

    /**
     * Build checkins array
     */
    buildCheckinArray(source) {
      let newCheckinList = []
      let currentTime = this.$options.filters.timeToMinutes(
        new Date().getTime()
      )
      for (let key in source) {
        if (source[key].length === 1) {
          // Add checkin to new checkin array
          newCheckinList.push(source[key][0])
        } else if (source[key].length > 1) {
          // Figure out which one to take
          source[key] = source[key].slice().sort((a, b) => {
            if (
              (currentTime > a.StartMinutes &&
                currentTime < a.EndMinutes &&
                a.StartMinutes < b.StartMinutes) ||
              ((currentTime < a.StartMinutes || currentTime > a.EndMinutes) &&
                a.StartMinutes < b.StartMinutes)
            ) {
              return 1
            } else {
              return -1
            }
          })
          newCheckinList.push(this.filterDouble(source[key]))
        }
      }
      return newCheckinList
    },

    /**
     * Filter double checkins of the same profile
     */
    filterDouble(doubleElements = []) {
      let temp = []
      // Check if checkin is on a location
      temp = this.checkedInOnLocation(doubleElements)

      // Check if checkin is on a event
      if (!temp.length) {
        temp = this.checkedInOnEvent(doubleElements)
      }

      // Check if checkin filled in his working on
      if (!temp.length) {
        temp = this.filledInWorkingOn(doubleElements)
      }
      return temp
    },

    /**
     * Check if checkin is checked in on an event
     */
    checkedInOnEvent(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.EventId > 0
      })
      if (temp.length) {
        return temp[0]
      }
      return []
    },

    /**
     * Check if checkin is checked in on a location
     */
    checkedInOnLocation(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.LocationId > 0
      })
      if (temp.length) {
        return temp[0]
      }
      return []
    },

    /**
     * Check if checkin is checked in on a location
     */
    filledInWorkingOn(checkins = []) {
      let temp = []

      temp = checkins.filter((checkin, index) => {
        return checkin.WorkingOn !== ''
      })
      if (temp.length) {
        return temp[0]
      } else {
        return checkins[0]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 500px) {
  .column {
    width: 100%;
  }
}

.teaserTile {
  @include rounded($gap);
  background-color: $primary-light;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  .cell-text,
  .cell-image {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cell-text {
    padding-bottom: 5px;
  }
  .cell-image {
    padding-top: 5px;
  }
  .font-icon {
    $iconSize: 80px;
    display: inline-block;
    height: $iconSize;
    width: $iconSize;
    font-size: $iconSize;
    line-height: 1;
    &:before {
      line-height: 1;
    }
  }
}

.goto-action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a:not(:first-child) {
    margin-left: $gap;
  }
}
</style>