import axios from "axios";
import store from "@/store/store";

export default {
  methods: {
    async getProfileProposals() {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v1/profile/proposal`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },

    async getProposal(proposalKey) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/proposal/${proposalKey}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },

    async acceptProposal(proposalKey) {
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/proposal/${proposalKey}/accept`,
        null,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },

    async proposalChangeRequest(proposalKey, comment) {
      let criteria = {
        Comment: comment
      }

      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/proposal/${proposalKey}/change`,
        JSON.stringify(criteria),
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },

    async cancelProposal(proposalKey, cancelReason = 0, reason = '') {
      let criteria = {
        CancelReason: cancelReason,
        Reason: reason
      }
      
      const response = await axios.put(
        `${process.env.VUE_APP_API_BASE_URL_V2}/api/v2/proposal/${proposalKey}/cancel`,
        JSON.stringify(criteria),
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters.getProfileToken
          }
        }
      );
      return response;
    },
  }
}