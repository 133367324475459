<template>
  <div class="box upcoming-wrapper p-0">
    <div class="cardFlip has-background-grey-light">
      <div
        v-for="(contentItem, index) in content"
        :key="'content_' + index"
        class="p-4 has-text-white card-side"
        :class="{'no-animation' : !hasAnimation}"
      >
        <span
          v-if="contentItem.icon === 'seat'"
          class="icon is-large has-margin-right"
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="fill: currentColor; fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"
          >
            <path
              id="s2m-icon-chair"
              d="M19.411,13.594l0.413,-0.797c0.31,-0.563 0,-0.984 -0.672,-0.984l-2.943,0l0,-3.938l0.878,0c0.671,0 1.342,-0.469 1.394,-1.125l1.136,-5.625c0.104,-0.609 -0.361,-1.125 -1.084,-1.125l-13.066,0c-0.671,0 -1.084,0.516 -1.033,1.125l1.137,5.719c0.103,0.609 0.723,1.078 1.394,1.078l0.878,0l0,3.937l-2.995,0c-0.672,0 -0.982,0.469 -0.672,1.032l0.465,0.75c0.155,0.422 0.62,0.703 1.084,0.843l-1.187,8.579c-0.052,0.421 0.565,0.937 1.033,0.937c0.464,0 0.981,-0.328 1.032,-0.703l1.188,-8.625l8.418,0l1.136,8.625c0.103,0.422 0.74,0.748 1.188,0.703c0.465,-0.047 0.929,-0.516 0.878,-0.938l-1.188,-8.578c0.516,-0.14 0.929,-0.515 1.188,-0.89Zm-5.371,-1.735l-3.977,0l0,-3.937l3.977,0l0,3.937Z"
              style="fill-rule:nonzero;"
            />
          </svg>
        </span>
        <span class="icon">
          <font-awesome-icon
            v-if="contentItem.icon === 'proposal'"
            :icon="['fa', 'file-invoice']"
            size="10x"
            :transform="{ rotate: -10 }"
          />
        </span>
        <div class="card-content p-0">
          <div
            v-text="contentItem.content.length"
            class="title-nrPresentation is-size-2-mobile is-size-1-tablet has-text-centered pt-6 pb-5 has-text-weight-bold"
            :class="contentItem.content.length ? 'has-text-white':'has-text-grey'"
          ></div>

          <div
            v-html="contentItem.title"
            class="title-item is-size-3 has-text-centered has-text-weight-bold has-text-white"
            :class="contentItem.content.length ? 'has-text-white':'has-text-grey'"
          ></div>

          <div class="has-text-centered">
            <router-link
              @click.native="$googleAnalytics.pushEvent(contentItem.routeName, contentItem.gaLabel, 'UpcomingBookings')"
              :to="{ name: contentItem.routeName }"
              class="button is-primary"
            >
              <span v-text="contentItem.buttonLabel"></span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import reservationProvider from '../../providers/reservation'
import proposalProvider from '../../providers/proposal'

export default {
  data() {
    return {
      content: [
        {
          title: this.$t('Components.Dashboard.Upcoming.Tab_Bookings'),
          content: [],
          routeName: 'Reservations',
          buttonClass: 'is-success',
          buttonLabel: this.$t('Components.Dashboard.Upcoming.Button_MyBookinks'),
          gaLabel: 'button-MyBookings',
          icon: 'seat'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      connectionStatus: 'getConnectionStatus',
      isLoggedIn: 'getIsLoggedIn'
    }),

    hasAnimation() {
      return this.content.length === 2
    }
  },

  watch: {
    isLoggedIn: {
      immediate: true,
      handler: async function(val) {
        if (!val) { return }

        await this.getReservations()
        await this.getProposals()

        this.$emit('hasUpcomingEvents', this.content[0].content.length || (this.content.length > 1 && this.content[1].content.length))
      }
    },
  },

  methods: {
    getReservations() {
      reservationProvider.methods.getReservations({ page: 0, itemsPerPage: 0 })
        .then(response => {
          this.content[0].content = response.data.Results
        })
    },
    getProposals() {
      proposalProvider.methods.getProfileProposals()
        .then(response => {
          if (response.data.Result.length) {
            this.content.push({
              title: this.$t('Components.Dashboard.Upcoming.Tab_Proposals'),
              content: response.data.Result,
              routeName: 'proposals',
              buttonClass: 'is-success',
              buttonLabel: this.$t('Components.Dashboard.Upcoming.Button_MyProposals'),
              gaLabel: 'button-MyProposals',
              icon: 'proposal'
            })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.upcoming-wrapper {
  height: 100%;
  position: relative;

  .cardFlip {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    perspective: 75rem;
    border-radius: 5px;
    display: flex;

    .card-side {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      backface-visibility: hidden;
      overflow: hidden;
      background-color: $blue-dark;
      border-radius: 5px;

      &:not(.no-animation) {
        &:nth-child(1) {
          animation: 10s flip infinite ease;
        }
        &:nth-child(2) {
          background-color: $primary;
          animation: 10s flipBack infinite ease;
          transform: rotateY(180deg);
        }
      }

      .icon {
        position: absolute;
        left: -5rem;
        bottom: 0;
        opacity: 0.1;
        z-index: 0;
        height: 14rem;
        width: 14rem;
      }

      .card-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 10px 0;

        div:nth-child(2) {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }

    @media screen and (max-width: 768px) and (min-width: 534px) {
      overflow: hidden;
      .card-side {
        position: static;
        backface-visibility: visible;
        border-radius: 0px;
        width: 50%;
        &:not(.no-animation) {
          &:nth-child(1),
          &:nth-child(2) {
            animation-play-state: paused;
            animation: none;
            transform: rotateY(0deg);
          }
        }
      }
    }
  }
}

@keyframes flip {
  // Show front
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  // Animate to back
  60% {
    transform: rotateY(180deg);
  }
  90% {
    transform: rotateY(180deg);
  }
  // Animate to front
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flipBack {
  // Show back
  0% {
    transform: rotateY(-180deg);
  }
  50% {
    transform: rotateY(-180deg);
  }
  // Animate to front
  60% {
    transform: rotateY(0deg);
  }
  90% {
    transform: rotateY(0deg);
  }
  // Animate to back
  100% {
    transform: rotateY(180deg);
  }
}
</style>
